<template>
  <div class="">
    <div class="small-base-text mb-8 mt-24">
      {{ $lang('checkoutResources', 'deliveryAddressUpdateHeading') }}
    </div>
    <div
      v-if="!openAddressUpdate && deliveryAddress"
      class="p-16 flex justify-between border border-grey600 rounded overflow-hidden"
    >
      <div class="text-sm font-medium leading-sm">
        <span class="block mb-2">{{ deliveryAddress.line1 }}</span>
        <span class="mr-6">{{ deliveryAddress.city }} </span>
        <span>{{ deliveryAddress.postalCode }}</span>
      </div>
      <div
        v-if="subscriptionIsActive"
        class="meta-info-regular-text underline capitalize ml-24 font-medium cursor-pointer"
        @click="(openAddressUpdate = true), $emit('openAddressUpdate')"
      >
        {{ $lang('checkoutResources', 'editAddress') }}
      </div>
    </div>
    <div v-if="openAddressUpdate && deliveryAddress" class="frontend-form mb-12 mt-12 md:mt-0">
      <div class="frontend-form-row">
        <div class="flex flex-1 pr-16 items-center relative">
          <TextInputAutocomplete
            :id="'intPersonAddressDelivery'"
            :placeholder="$lang('checkoutResources', 'formAddress')"
            :type="'address-line1'"
            :form-input="true"
            :error="addressError"
            :init-value="deliveryAddress.line1"
            @submit-input="addressInput"
            @set-city-value="cityInput"
            @set-postal-value="postalCodeInput"
          />
          <div
            class="underline flex items-center select-none cursor-pointer uppercase text-tiny font-medium absolute right-16 top-8"
            @click="shouldShowCOAddress = !shouldShowCOAddress"
          >
            {{
              $lang(
                'checkoutResources',
                shouldShowCOAddress
                  ? 'formHideCOAddressAction'
                  : 'formShowCOAddressAction'
              )
            }}
          </div>
        </div>
      </div>
      <div v-if="shouldShowCOAddress" class="frontend-form-row">
        <div class="flex flex-1 pr-16 items-center">
          <TextInput
            :id="'intPersonAddress'"
            :placeholder="$lang('checkoutResources', 'formCOAddress')"
            :type="'address'"
            :form-input="true"
            :init-value="deliveryAddress.careOf"
            @submit-input="coAddressInput"
          />
        </div>
      </div>
      <div class="frontend-form-row">
        <div class="frontend-form-cell">
          <TextInput
            :id="'intPersonCity'"
            :placeholder="$lang('checkoutResources', 'formCity')"
            :type="'text'"
            :form-input="true"
            :error="cityError"
            :init-value="deliveryAddress.city"
            @submit-input="cityInput"
          />
        </div>
        <div class="frontend-form-cell">
          <TextInput
            :id="'intPersonPostalCode'"
            :placeholder="$lang('checkoutResources', 'formPostalCode')"
            :type="'zip'"
            :form-input="true"
            :error="postalCodeError"
            :init-value="deliveryAddress.postalCode"
            @submit-input="postalCodeInput"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import TextInput from '~/components/form-elements/TextInput.vue';
import TextInputAutocomplete from '../form-elements/TextInputAutocomplete.vue';
import { ref, computed, onMounted } from 'vue';

import {
  type ISubscriptionOverview,
  type IAddress,
  SubscriptionStatus
} from '~/models/api-types';
const { $lang } = useNuxtApp();
const { apiGet, lastError } = useApiFetch();
const config = useGlobalContentStore().config;

const props = defineProps<{
  subscription: EditableSubscription,
  isMobile: boolean
}>();
const emit = defineEmits<{
  (event: 'submitDeliveryAddress', value: {
    address: IAddress;
    subscription: EditableSubscription
  }): void,
  (event: 'openAddressUpdate') : void,
}>();

const addressError = ref('');
const cityError = ref('');
const postalCodeError = ref('');
const shouldShowCOAddress = ref(false);
const openAddressUpdate = ref(false);
const deliveryAddress: Ref<IAddress | undefined> = ref();

interface EditableSubscription extends ISubscriptionOverview {
  editedSubscription: ISubscriptionOverview;
  edit: boolean;
  deliveryAddress: IAddress;
}

onMounted(async() => {
  await fetchDeliveryAddress();
});

const subscriptionIsActive = computed(() => {
  return props.subscription.status.toString() === SubscriptionStatus[SubscriptionStatus.Active];
});

const addressInput = (input: string) => {
  deliveryAddress.value!.line1 = input;
  emit('submitDeliveryAddress', {
    address: deliveryAddress.value!,
    subscription: props.subscription,
  });
};

const coAddressInput = (input: string) => {
  deliveryAddress.value!.careOf = input;
  emit('submitDeliveryAddress', {
    address: deliveryAddress.value!,
    subscription: props.subscription,
  });
};

const cityInput = (input: string) => {
  deliveryAddress.value!.city = input;
  emit('submitDeliveryAddress', {
    address: deliveryAddress.value!,
    subscription: props.subscription,
  });
};

const postalCodeInput = (input: string) => {
  deliveryAddress.value!.postalCode = input;
  emit('submitDeliveryAddress', {
    address: deliveryAddress.value!,
    subscription: props.subscription,
  });
};

const fetchDeliveryAddress = async()  =>{
  const { currentMarket, language } = config;
  const query = `user/subscriptions/${props.subscription.subscriptionId}/deliveryaddress/?subscriptionId=${props.subscription.subscriptionId}&${language}&countryCode=${currentMarket?.countryCode}`;
  const res = await apiGet(query);
  if (res) {
    deliveryAddress.value = res;
  }
};
</script>
